import React from 'react';

const WaitlistBox = () => {
  return (
    <div className="bg-gray-800 p-6 md:p-8 rounded-lg max-w-md mx-auto mb-12 md:mb-16 shadow-lg border border-purple-500">
      <h2 className="text-xl md:text-2xl font-bold mb-3 md:mb-4">Be the First to Know</h2>
      <p className="mb-4 md:mb-6">Join the waitlist to get early access at launch!</p>
      <a 
        href="https://forms.gle/gqTEq5SwVGmMudFdA"
        target="_blank" 
        rel="noopener noreferrer" 
        className="inline-block bg-purple-600 hover:bg-purple-700 px-6 py-3 rounded-md transition-colors duration-300 text-base md:text-lg font-semibold"
      >
        Join Waitlist
      </a>
    </div>
  );
};

export default WaitlistBox;
